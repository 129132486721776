/*eslint prefer-rest-params: "off"*/

import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useAccountStore } from '../../utils/account';
import AdsFallback from './AdsFallback';

window.nitroAds = window.nitroAds || {
  createAd: function () {
    window.nitroAds.queue.push(['createAd', arguments]);
  },
  addUserToken: function () {
    window.nitroAds.queue.push(['addUserToken', arguments]);
  },
  queue: [],
};

const NitroPay = () => {
  const accountStore = useAccountStore();
  const [showFallback, setShowFallback] = useState(false);

  useEffect(() => {
    let userId = Cookies.get('userId');
    const refreshState = async () => {
      if (!userId) {
        const state = useAccountStore.getState();
        if (state.isPatron) {
          accountStore.setIsPatron(false);
        }
        return;
      }

      const response = await fetch(
        `https://www.th.gl/api/patreon?appId=bemfloapmmjpmdmjfjgegnacdlgeapmkcmcmceei`,
        { credentials: 'include' },
      );
      try {
        const body = await response.json();
        if (!response.ok) {
          console.warn(body);
          accountStore.setIsPatron(false);
        } else {
          console.log(`Patreon successfully activated`);
          accountStore.setIsPatron(true, userId);
        }
      } catch (err) {
        console.error(err);
        accountStore.setIsPatron(false);
      }
    };
    refreshState();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const newUserId = Cookies.get('userId');
        if (newUserId !== userId) {
          userId = newUserId;
          refreshState();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (
      navigator.userAgent.includes('Overwolf') ||
      accountStore.isPatron ||
      location.href === 'http://localhost:3001/'
    ) {
      return;
    }

    window['nitroAds'].createAd('am-video', {
      format: 'video-nc',
      video: {
        float: 'always',
      },
    });

    const script = document.createElement('script');
    script.src = 'https://s.nitropay.com/ads-1487.js';
    script.setAttribute('data-cfasync', 'false');
    script.setAttribute('data-log-level', 'silent');
    script.async = true;

    const now = Date.now();
    const intervalId = setInterval(() => {
      console.log('NitroAds setInterval');
      if (Date.now() - now > 1500) {
        console.log('NitroAds setInterval failed to load');
        setShowFallback(true);
        clearInterval(intervalId);
      }
    }, 1500);

    script.onload = () => {
      clearTimeout(intervalId);
      if (
        'nitroAds' in window &&
        (window.nitroAds as NitroAds).siteId === 1487
      ) {
        console.log('NitroAds script is ready');
        setShowFallback(false);
      } else {
        console.log('NitroAds onReady failed to load');
        setShowFallback(true);
      }
    };

    document.body.appendChild(script);

    return () => {
      clearTimeout(intervalId);
      document.body.removeChild(script);
    };
  }, []);

  if (accountStore.isPatron) {
    return <></>;
  }

  return (
    <>
      {showFallback && <AdsFallback />}
      <div id="am-video" />
    </>
  );
};

export default NitroPay;

declare global {
  interface Window {
    nitroAds: NitroAds;
  }
}

export type NitroAdOptions = any;
export type UserDataEncoding = 'PLAIN' | 'SHA-1' | 'SHA-256';

export interface NitroAd {
  new (id: string, options: NitroAdOptions): NitroAd;
  id: string;
  options: NitroAdOptions;
  onNavigate: () => void;
  renderContainers: () => boolean;
}

export interface NitroAds {
  createAd: (
    id: string,
    options: NitroAdOptions,
  ) => NitroAd | Promise<NitroAd> | Promise<NitroAd[]>;
  stop: () => void;
  addUserToken: (email: string, encoding?: UserDataEncoding) => Promise<void>;
  clearUserTokens: () => void;
  queue: ([string, any, (value: unknown) => void] | [string, any])[];
  loaded: boolean;
  siteId: number;
}
